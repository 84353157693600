import * as Sentry from '@sentry/browser'

export function useComponentError(error: Error): void {
    if (import.meta.client) {
        // Send error to sentry
        Sentry.captureException(error)
    }

    // Do not cache a page with faulty components
    useCacheControl({
        maxAge: 0,
        sMaxAge: 0,
        public: false,
    })
}
